<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import FaviconIcon from './icons/FaviconIcon.vue'
import IconSignOut from './icons/IconSignOut.vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { ROLES } from '@/util/user-types.constants'

const roleMapping: { [key: string]: string } = {
  staff: ROLES.STAFF,
  illuminateed_employee: ROLES.ADMIN,
  default: ROLES.USER
}

const tooltipRef = ref<any>(null)
const authStore = useAuthStore()
const fullName = computed(() => authStore.getUserName)
const firstName = computed(() => authStore.getFirstName)
const lastName = computed(() => authStore.getLastName)
const site = computed(() => authStore.getClientName)
const type = computed(() => authStore.getUserType)

const getInitials = computed(() => {
  const first = firstName.value && firstName.value !== '' ? firstName.value.charAt(0) : 'A'
  const last = lastName.value && lastName.value !== '' ? lastName.value.charAt(0) : 'U'
  return `${first}${last}`
})

const getRole = computed(() => {
  const userType = type.value || 'default'
  return roleMapping[userType] || roleMapping.default
})
const show = ref(false)
function closeTooltip() {
  show.value = false
}

function handleClickOutside(event: MouseEvent) {
  const tooltipEl = tooltipRef.value?.$el
  const activatorEl = tooltipRef.value?.activatorEl

  if (
    activatorEl &&
    !activatorEl.contains(event.target as Node) &&
    tooltipEl &&
    !tooltipEl.contains(event.target as Node)
  ) {
    closeTooltip()
  }
}

function logout() {
  closeTooltip()
  authStore.logout()
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>
<template>
  <v-app-bar :elevation="2">
    <template v-slot:prepend>
      <FaviconIcon data-test="logo-dna" class="header__icon" />
    </template>
    <template v-slot:append>
      <v-menu v-model="show" :close-on-content-click="false" offset="2" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            data-test="logo-user-button"
            class="header__tooltip__button mr-4"
            :active="show"
            :ripple="false"
            icon
            v-bind="props"
          >
            {{ getInitials }}
          </v-btn>
        </template>

        <v-card class="header__tooltip pa-3">
          <article data-test="user-tooltip-content" class="d-flex flex-column">
            <span>
              <span
                data-test="user-tooltip-full-name"
                class="tooltip__content__user"
                data-dd-privacy="mask"
                >{{ `${fullName} - ` }}</span
              >
              {{ getRole }}
            </span>
            <span data-test="user-tooltip-site"> {{ site }} </span>
          </article>
          <v-divider class="content-separator"></v-divider>
          <v-btn
            class="logout-btn"
            variant="text"
            density="compact"
            data-test="logout-header-button"
            @click="logout()"
          >
            <template v-slot:prepend>
              <IconSignOut color="#202020" class="logout-icon" />
            </template>
            <span>Log Out</span>
          </v-btn>
        </v-card>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<style scoped>
:deep(.v-overlay__content) {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  border-radius: 8px !important;
}

.header__tooltip {
  color: #54585e;
}

:deep(.v-btn--active > .v-btn__overlay),
:deep(.v-btn--active:hover > .v-btn__overlay) {
  opacity: 0;
}

:deep(.v-btn__prepend) {
  margin-right: 0;
}

.logout-btn {
  margin-top: 20px;
  text-transform: none;
  padding-left: 4px;
  color: #000000;
}

.logout-btn > :deep(.v-btn__overlay) {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.logout-icon {
  margin-right: 8px;
}

.content-separator {
  display: block;
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 100%;
  border: 1px solid;
  margin-top: 10px;
}

.header__icon {
  margin-left: 16px;
  height: 40px !important;
}

.header__tooltip__button {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
  font-family: 'Cerebri Sans Medium';
  background-color: #c6eddf;
}

.header__tooltip__button:hover {
  border: 2px solid #cf3a4e;
}

.tooltip__content__user {
  color: #000000;
}
</style>
